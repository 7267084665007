module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Půjčovna kol BeRider","short_name":"Půjčovna kol BeRider","description":"Kola od 500 Kč/den. Zkuste karbonové cross-country nebo jen pohodovou projížku na elektrokole.","start_url":"/","background_color":"#77DF00","theme_color":"#77DF00","display":"standalone","icon":"src/images/berider-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5ef1dd2e73244eb0f9c8ef99ae5d3f8b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":false,"disableMinification":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-5DZVC2L"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://20c222454fea4db1a9bbf8a265cc59e8@o222805.ingest.sentry.io/5353596","environment":"production","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
